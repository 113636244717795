.button {
  cursor: pointer;
  right: 10%;
  top: 50%;
  position: absolute;
  z-index: 2;
  background: transparent;
  border: none;
  @media (max-width: 1440px) {
    right: 17%;
  }
  @media (max-width: 999px) {
    top: 99%;
  }
  @media (min-width: 551px) and (max-width: 999px) {
    top: 94%;
  }
}
