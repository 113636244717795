@use '/src/styles/variables' as *;

.modal {
  position: absolute;
  top: 11rem;
  left: 0;
  right: 0;
  background-color: $white;
  list-style-type: none;
  border-radius: 0 0 45px 45px;
  border-left: 2px solid #f3e9e9;
  border-right: 2px solid #f3e9e9;
  border-bottom: 2px solid #f3e9e9;
  padding: 30px 30px 20px 30px;
  z-index: 1;
  @media (max-width: 999px) {
    top: 17rem;
  }
}
