@use '/src/styles/variables' as *;

.sliderDays {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-bottom: 30px;
  margin-bottom: 15px;
  border-bottom: 1px solid $light-grey;
  @media (max-width: 999px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.sliderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
