@use '/src/styles/variables' as *;

.container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: $beige-gradient;
  padding: 100px 0 0 0;
}
