@use '/src/styles/variables' as *;

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding-top: 20px;
  max-width: 100%;
  height: 100%;
  background: $beige-gradient;
  padding: 150px 0px 30px 140px;
  overflow: hidden;
  @media (max-width: 999px) {
    flex-direction: column;
    padding: 320px 16px;
  }
  @media (min-width: 1000px) and (max-width: 1440px) {
    padding: 150px 0px 30px 80px;
  }
}

.wrapper {
  align-items: center;
  width: 100%;
}
