.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  overflow-wrap: break-word;
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
  @media (max-width: 999px) {
    padding: 0;
    width: fit-content;
  }
}
.img {
  max-width: 106px;
  max-height: 106px;
  transition: transform 0.3s ease-in-out;
  @media (max-width: 999px) {
    max-width: 320px;
    max-height: 320px;
  }
  &:hover {
    transition: transform 0.3s ease-in-out;
    transform: translate(0%, 0%) scale3d(1.1, 1.1, 1);
    @media (max-width: 999px) {
      transition: none;
      transform: none;
    }
  }
}
.title {
  display: flex;
  text-align: center;
  max-width: 150px;
  line-height: 15px;
  font-weight: 600;
  @media (max-width: 999px) {
    display: block;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 15%;
    color: white;
    font-weight: 800;
    white-space: pre-line;
    max-width: 100%;
    line-height: 1.8rem;
    font-size: 35px;
  }
}
