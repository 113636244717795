@use '/src/styles/variables' as *;

.container {
  position: relative;
  background-color: #181818;
  @media (max-width: 999px) {
    height: 140vh;
  }
  &__img {
    display: block;
    @media (max-width: 999px) {
      display: none;
    }
  }
}
.box {
  position: absolute;
  background-color: white;
  bottom: 0;
  right: 0;
  width: 65%;
  border-radius: 0 0 0 45px;
  height: 100%;
  background-color: $light-beige;
  @media (max-width: 999px) {
    // position: relative;
    // bottom: 1rem;
    display: flex;
    flex-direction: column;
    // height: 100vh;
    width: 100%;
    border-radius: 0 0 45px 45px;
  }
  @media (min-width: 2100px) {
    width: 80%;
  }
}

.iconsBox {
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 45px 45px;
  @media (max-width: 999px) {
    position: relative;
    z-index: 1;
    height: fit-content;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    &__item {
      margin: 0rem 2rem;
      @media (max-width: 999px) {
        margin: 1rem 0;
      }
    }
    @media (max-width: 999px) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 40px;
      margin-top: 100px;
    }
  }
}
.iconsText {
  text-align: center;
}

.adresDataBox {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 30px 0 50px 0;
  background-color: $light-beige;
  border-radius: 0 0 0 45px;
  @media (max-width: 999px) {
    position: relative;
    flex-direction: column;
    border-radius: 0 0 45px 45px;
    padding: 0px 40px;
  }
}

.bold {
  font-weight: 900;
  font-size: 24px;
}

.regular {
  font-size: 14px;
}

.contactItem {
  margin-left: 70px;
  margin-right: 20px;
  @media (max-width: 999px) {
    margin: 7px 0;
  }
}
