@use '/src/styles/variables' as *;
@use '/src/styles/animations' as *;

.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(10, 10, 10, 0.5);
  opacity: 0;
  animation-name: fadeInAnimation;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

.wrapper {
  width: 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    width: 90%;
  }
  &__img {
    width: 100%;
    border-radius: 20px;
  }
  &__button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
