@use '/src/styles/variables' as *;
.listItem {
  cursor: pointer;
  font-size: 13px;
  border-bottom: 1px solid $light-grey;
  & p {
    padding: 13px 0 15px 0;
    font-size: 14px;
  }
}
.button {
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
}
