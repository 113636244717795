@keyframes openTop {
  0% {
    transform: translateY(-100%) rotate(0deg);
  }
  100% {
    transform: translateY(0%) rotate(0deg);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes leftSlideAnimation {
  0% {
    transform: translateX(100%) rotate(0deg);
  }

  100% {
    transform: translateX(0%) rotate(0deg);
  }
}

@keyframes rightSlideAnimation {
  0% {
    transform: translateX(-100%) rotate(0deg);
  }

  100% {
    transform: translateX(0%) rotate(0deg);
  }
}
