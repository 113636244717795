@use '/src/styles/variables' as *;
.container {
  background-color: #181818;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  padding: 0px;
  @media (max-width: 999px) {
    flex-direction: column;
  }
}
.logo {
  max-width: 100%;
  max-height: 100%;
  padding: 60px;
  @media (max-width: 999px) {
    padding: 50px 0 0 30px;
  }
}
.iconsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 75px;
  list-style: none;
  @media (max-width: 999px) {
    margin: 30px 0 30px 0;
    // justify-content: flex-end;
  }
}
.listItem {
  margin-right: 33px;
}
.contactsText {
  color: $white;
  line-height: 24px;
  padding: 10px 0;
  @media (max-width: 999px) {
    // line-height: 24px;
  }
}
.wrapperContacts {
  padding: 60px;
  @media (max-width: 999px) {
    padding: 30px 0 0 30px;
  }
}
