@use '/src/styles/variables' as *;
@use '/src/styles/animations' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 45px 45px;
  overflow: hidden;
  z-index: -1;
}

.header {
  position: relative;
}

.img {
  width: 100%;
  @media (max-width: 1200px) {
    width: fit-content;
  }
}

.deliveryFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $light-black;
  width: 100%;
  padding: 10px 0;
}

.p {
  color: $white;
}

.h1 {
  color: $beige;
}
