@use '/src/styles/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 180px;
  background-color: $white;
  @media (max-width: 999px) {
    padding: 50px;
  }
}

.iconsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0px 100px;
  text-align: center;

  @media (max-width: 999px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px 0px;
  }
}
.textHeader {
  text-align: center;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 60px;
}
