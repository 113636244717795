@use '/src/styles/variables' as *;

.container {
  position: relative;
  @media (max-width: 999px) {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
}
.img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  @media (max-width: 999px) {
    display: none;
  }
}
.textWrapper {
  position: absolute;
  top: 30%;
  left: 10%;
  max-width: 30%;
  @media (min-width: 1000px) and (max-width: 1440px) {
    top: 10%;
    max-width: 40%;
  }
  @media (max-width: 999px) {
    position: static;
    max-width: 70%;
  }
}
.textHeader {
  font-family: 'Proxima Nova', sans-serif !important;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 24px;
}
.textFooter {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $grey;
}
.span {
  font-family: 'Proxima Nova', sans-serif !important;
  color: $yellow;
}
