@use '/src/styles/variables' as *;

.buttonsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem 0.5rem;
  padding-bottom: 15px;
  padding-top: 2rem;
  @media (max-width: 999px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
