@use '/src/styles/variables' as *;

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 30px;
  margin-bottom: 5px;
  border-radius: 45px;
  border: 2px solid $yellow;
  width: 100%;
  background-color: $white;
  z-index: 2;
  @media (max-width: 999px) {
  }
}

.p {
  font-size: 14px;
}
