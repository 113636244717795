.documents {
  padding-top: 100px;
  padding-bottom: 30px;
  text-align: justify;
  margin: 10rem 3rem;

  .center {
    text-align: center;
  }

  p {
    font-size: 18px;
    color: #000;
  }

  table {
    border: 1px solid #ccc;
    th {
      border-right: 1px solid #ccc;

      &:first-child {
        width: 200px;
      }

      &:last-child {
        border-right: none;
      }
    }

    td {
      border-right: 1px solid #ccc;

      &:last-child {
        border-right: none;
      }
    }
  }

  @media (min-width: 992px) {
    padding-top: 160px;
  }
}
