@use '/src/styles/variables' as *;

.footerDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;

  &__p {
    color: white;
  }
  @media (min-width: 1000px) and (max-width: 1240px) {
    padding: 1px 0;
  }
  @media (min-width: 1240px) and (max-width: 1320px) {
    padding: 3px 0;
  }
  @media (min-width: 1320px) and (max-width: 1519px) {
    padding: 6px 0;
  }
  @media (min-width: 1520px) and (max-width: 1690px) {
    padding: 10px 0;
  }
}

.fontBeige {
  color: $beige;
  font-size: 24px;
  font-weight: 600;
}
.footerDivWithButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  @media (max-width: 1440px) {
    padding: 9px 0;
  }
  @media (max-width: 999px) {
    flex-direction: column;
    align-items: flex-end;
  }
}
.footer {
  margin: 30px;
  color: $white;
}
.yellowButton {
  padding: 20px 5px;
  width: 100%;
  border-radius: 45px;
  border: none;
  background-color: $yellow;
  margin-bottom: 25px;
}

.p {
  color: white;
  margin-right: 60px;
  @media (max-width: 999px) {
    margin: 0 0 20px 0;
  }
}
