@use '/src/styles/variables' as *;

.container {
  top: 100px;
  left: 0;
  right: 0;
  padding: 100px 0 0 0;

  &__img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__imgSledzNas {
    display: block;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1200px) {
      width: 100%;
      height: 100%;
    }
  }
}

.sledzNasContainer {
  position: relative;
  background: #f4e7d7;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.wrapper {
  position: absolute;
  top: 15rem;
  left: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1200px) {
    position: static;
  }

  &__h2 {
    font-size: 80px;
    color: $yellow;
    font-family: 'Proxima Nova', sans-serif !important;
    font-weight: 700;
    @media screen and (max-width: 1200px) {
      margin-top: 20px;
      font-size: 50px;
    }
  }

  &__p {
    font-size: 24px;
    color: #282828;
    font-weight: 500;
    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }
  }
}

.socialBox {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
