@use '/src/styles/animations' as *;

.imgWrapper {
  animation: fadeInAnimation 0.5s;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1000px) and (max-width: 1240px) {
    position: absolute;
    top: 8%;
  }
  @media (min-width: 1240px) and (max-width: 1320px) {
    position: absolute;
    top: 6%;
  }
  @media (min-width: 1320px) and (max-width: 1519px) {
    position: absolute;
    top: 4%;
  }
  @media (min-width: 1520px) and (max-width: 1690px) {
    position: absolute;
    top: 2%;
  }

  @media (max-width: 999px) {
    display: none;
  }
}
.img {
  max-width: 90%;
  max-height: 90%;
  @media (min-width: 1000px) and (max-width: 1240px) {
    max-width: 45%;
    max-height: 45%;
  }
  @media (min-width: 1240px) and (max-width: 1320px) {
    max-width: 60%;
    max-height: 60%;
  }
  @media (min-width: 1320px) and (max-width: 1519px) {
    max-width: 70%;
    max-height: 70%;
  }
  @media (min-width: 1520px) and (max-width: 1690px) {
    max-width: 80%;
    max-height: 80%;
  }
}
