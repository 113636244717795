@use '/src/styles/variables' as *;
@use '/src/styles/animations' as *;

.listItem {
  padding: 0 20px;
  text-decoration: none;
  color: $black-rgb;
  font-size: 14px;

  &__title:hover {
    color: #000000;
  }
  &__fillDiv {
    width: 100%;
    height: 0.6rem;
    margin-bottom: 2rem;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &__fillDivTransparent {
    width: 100%;
    height: 0.6rem;
    background-color: transparent;
    margin-bottom: 2rem;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    padding: 0 10px;
  }

  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 14px 30px;
    border-bottom: 1px solid $light-grey;
    width: 100%;
  }
}
