@use '/src/styles/variables' as *;
@use '/src/styles/animations' as *;

.prevAnimation {
  animation: leftSlideAnimation 0.2s ease-in-out;
}

.nextAnimation {
  animation: rightSlideAnimation 0.2s ease-in-out;
}

.con {
  height: 100vh;
  @media (max-width: 999px) {
    margin-top: -7%;
  }
  @media (max-width: 520px) {
    margin-top: -5%;
  }
  @media (max-width: 620px) {
    margin-top: -5%;
  }
  @media (max-width: 600px) {
    margin-top: 30%;
  }
}

.containerText {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 100px;
  color: white;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 145px;
  }
  @media (max-width: 600px) {
    font-size: 57px;
  }
}
.p {
  font-family: Proxima Nova, sans-serif !important;
  font-weight: 900;
  color: white;
  @media (max-width: 420px) {
    line-height: 3.5rem;
  }
  @media (min-width: 1920px) {
    line-height: 120px;
  }
  @media (min-width: 1000px) and (max-width: 1919px) {
    line-height: 80px;
  }
}

.imgcenter {
  max-width: 70%;
  max-height: 70%;
  z-index: 1;
  @media (min-width: 1920px) {
    max-width: 80%;
    max-height: 80%;
  }
  @media (min-width: 1000px) and (max-width: 1439px) {
    max-width: 55%;
    max-height: 55%;
  }
  @media (max-width: 850px) {
    max-width: 100%;
    max-height: 80%;
  }
}
.wrappercenter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.img {
  max-width: 80%;
  max-height: 80%;
}
.wrapper {
  z-index: 1;
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1920px) {
    bottom: 17%;
  }
  @media (max-width: 520px) {
    bottom: 10%;
  }
  @media (max-width: 620px) {
    bottom: 15%;
  }
  @media (max-width: 999px) {
    bottom: 15%;
  }
}
.span {
  max-width: 700px;
  overflow-wrap: break-word;

  @media (max-width: 999px) {
    max-width: 380px;
  }
  @media (max-width: 420px) {
    max-width: 300px;
  }
}
.dietName {
  font-family: Proxima Nova, sans-serif !important;
  line-height: 6rem;
  text-align: center;
  margin: 0;
  font-size: 112px;
  font-weight: 800;
  color: white;
  overflow-wrap: break-word;

  @media (max-width: 420px) {
    line-height: 2.8rem;
    font-size: 50px;
  }
  @media (min-width: 421px) and (max-width: 1200px) {
    line-height: 4rem;
    font-size: 70px;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: -50px;
  @media (max-width: 1920px) {
    // margin-bottom: -20px;
  }
  @media (max-width: 999px) {
    margin-bottom: -120px;
    top: 90%;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
  }
}
.buttonsWrapperItem {
  margin: 0 15px;
  @media (max-width: 999px) {
    margin: 10px 0;
  }
}
