@use '/src/styles/variables' as *;

.input {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background-color: $yellow;
  outline: none;
  padding: 0;
  margin: 0;
}

.input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  background: #282828;
}
