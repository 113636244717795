@use '/src/styles/variables' as *;

.container {
  background-color: #282828;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 0;
  @media (max-width: 999px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4rem 3rem 3rem 3rem;
}
.textHeader {
  font-size: 14px;
  color: white;
  font-weight: 700;
  margin-bottom: 0.9rem;
}
.textList {
  color: $white;
  color: $white-rgb;
  font-size: 14px;
  font-weight: 400;
  margin: 0.7rem 0;
}
