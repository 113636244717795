.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.icon {
  max-width: 120%;
  max-height: 100%;
  margin-bottom: 3px;
}

.spanText {
  font-size: 14px;
  font-weight: 400;
}
