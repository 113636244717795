@use '/src/styles/variables' as *;

.list {
  position: absolute;
  left: 0;
  right: 0;
  top: 30rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  list-style: none;
  padding: 0 20rem;
  z-index: 1;
  @media (min-width: 1000px) and (max-width: 1240px) {
    padding: 0 2rem;
  }
  @media (min-width: 1240px) and (max-width: 1320px) {
    padding: 0 3rem;
  }
  @media (min-width: 1320px) and (max-width: 1519px) {
    padding: 0 5rem;
  }
  @media (min-width: 1520px) and (max-width: 1690px) {
    padding: 0 18rem;
  }

  &__item {
    max-width: 6rem;
    max-height: 6rem;
  }

  @media (max-width: 999px) {
    padding: 0 15px 40px 15px;
  }
}

.deliveryTitle {
  margin: 0;
  text-align: center;
}
