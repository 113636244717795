.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__p {
    margin-top: 10px;
    @media screen and (max-width: 1200px) {
      font-size: 10px;
    }
  }
}
