.button {
  cursor: pointer;
  left: 10%;
  top: 50%;
  position: absolute;
  z-index: 2;
  background: transparent;
  border: none;
  @media (max-width: 1440px) {
    left: 17%;
  }
  @media screen and (max-width: 550px) {
    top: 99%;
  }
  @media (min-width: 551px) and (max-width: 999px) {
    top: 94%;
  }
}
