@use '/src/styles/variables' as *;
@use '/src/styles/animations' as *;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
    background-color: $white;
    height: 100vh;
    animation: openTop 0.5s ease-in-out;
  }
}

.listItemWrapper {
  list-style: none;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    padding: 7rem 2rem 0rem 2rem;
  }
}
.listItem {
  margin: 0 25px;
  text-decoration: none;
  color: $black-rgb;
  font-size: 14px;

  &__fillDiv {
    width: 100%;
    height: 0.6rem;
    margin-bottom: 2rem;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &__fillDivTransparent {
    width: 100%;
    height: 0.6rem;
    background-color: transparent;
    margin-bottom: 2rem;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    margin: 0 10px;
  }
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 14px 2rem;
    border-bottom: 1px solid $light-grey;
    width: 100%;
  }
}
.listItemWrapperLeft {
  position: absolute;
  top: 0;
  right: 0;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 1200px) {
    position: static;
    margin-top: 1.5rem;
    flex-direction: column;
  }
}

.listItemButton {
  padding: 0 20px;
  text-decoration: none;

  @media (max-width: 1440px) {
    padding: 0 5px;
  }
  @media (max-width: 1200px) {
    padding: 10px 0;
  }
}
.buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}
.iconsWrapper {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listItemIcon {
  margin: 0 12.5px;
  text-decoration: none;
  color: $black-rgb;
  font-size: 14px;
}
