@use '/src/styles/variables' as *;

.container {
  position: absolute;
  z-index: 1;
  // top: 10%;
  left: 0;
  right: 0;
  background-color: $white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0 0 45px 45px;
  padding-bottom: 28px;
  @media (max-width: 999px) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
}
