.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  width: 100%;
  height: auto;
  &__wrapper {
    display: flex;
    align-items: center;
  }
}
