@use '/src/styles/variables' as *;

.container {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 13.2rem;
  overflow-wrap: break-word;
  @media (max-width: 1200px) {
    top: 8.5rem;
    padding: 0 1rem;
  }
}
.title {
  font-size: 40px;
  font-weight: 900;
  padding: 0 0 30px 0;
  @media (max-width: 1200px) {
    line-height: 2.5rem;
    text-align: center;
  }
}
.wrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 45px 45px 45px 45px;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25);
}
.input {
  border: 2px solid $yellow;
  border-radius: 45px;
  padding: 20px 30px;
  font-size: 16px;
  width: 100%;
  z-index: 1;
}
