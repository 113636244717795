$yellow: #ffb526;
$black: black;
$light-black: #282828;
$white: #ffffff;
$black-rgb: rgba(0, 0, 0, 0.7);
$white-rgb: rgba(255, 255, 255, 0.6);
$light-grey: #f3e9da;
$grey: #625847;
$beige: #e9b993;
$light-beige: #f3e9d9;
$beige-gradient: linear-gradient(180deg, #f3e9da 0%, #cfc0a7 100%);
