@use '/src/styles/variables' as *;

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.img {
  max-width: 60%;
  max-height: 60%;
  z-index: 1;
}

.wrapperleft {
  position: absolute;
  top: 0;
  left: -23%;
  z-index: 1;
}
