@use '/src/styles/variables' as *;

.dailyCost {
  padding: 0 30px;
  width: 100%;
  @media (max-width: 1440px) {
  }
}

.footerDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  @media (max-width: 1440px) {
    padding: 9px 0;
  }
}

.fontBeige {
  color: $beige;
  font-size: 24px;
  font-weight: 600;
}
