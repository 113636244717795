@use '/src/styles/variables' as *;

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  background: $beige-gradient;
  @media (max-width: 999px) {
    margin-top: 7rem;
    justify-content: center;
  }
}
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $white;
  width: 60%;
  border-radius: 0 45px 45px 0;
  padding: 0 24px;
  @media (max-width: 999px) {
    position: relative;
    width: 100%;
    border-radius: 0;
  }
}
.priceWrapper {
  position: absolute;
  top: 7%;
  background-color: $light-black;
  border-radius: 0 0 30px 30px;
  margin-left: 200px;
  z-index: 2;
  @media (min-width: 1000px) and (max-width: 1240px) {
    margin-left: 20px;
  }
  @media (min-width: 1240px) and (max-width: 1320px) {
    margin-left: 50px;
  }
  @media (min-width: 1320px) and (max-width: 1519px) {
    margin-left: 70px;
  }
  @media (min-width: 1520px) and (max-width: 1690px) {
    margin-left: 150px;
  }
  @media (max-width: 999px) {
    position: relative;
    margin: 0;
    width: 100%;
  }
}
.whiteWrapper {
  background-color: $white;
  border-radius: 0 0 30px 30px;
  width: 100%;
  padding: 30px 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__h6 {
    font-size: 14px;
    margin-bottom: 15px;
  }

  & h1 {
    padding-bottom: 20px;
    font-weight: 900;
    font-size: 40px;
    @media (max-width: 999px) {
      padding-left: 15px;
      padding-top: 80px;
    }
  }
}
