@use '/src/styles/animations' as *;

.prevAnimation {
  animation: leftSlideAnimation 0.2s forwards ease-in-out;
}

.nextAnimation {
  animation: rightSlideAnimation 0.2s forwards ease-in-out;
}

.wrapperleft {
  position: absolute;
  top: 0;
  left: -25rem;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 1;
  @media (min-width: 1000px) and (max-width: 1120px) {
    left: -40%;
  }
  @media (min-width: 1120px) and (max-width: 1210px) {
    left: -35%;
  }
  @media (min-width: 1210px) and (max-width: 1320px) {
    left: -25%;
  }
  @media (min-width: 1320px) and (max-width: 1439px) {
    left: -20%;
  }
  @media (max-width: 999px) {
    display: none;
  }
}
.img {
  max-width: 70%;
  max-height: 70%;
  z-index: 1;
  @media (min-width: 1000px) and (max-width: 1439px) {
    max-width: 55%;
    max-height: 55%;
  }
  @media (min-width: 1920px) {
    max-width: 80%;
    max-height: 80%;
  }
}
