@use '/src/styles/variables' as *;

.container {
  width: 100%;
  min-height: 100vh;
  background-color: $light-beige;
}

.iframe {
  width: 100%;
  min-height: 130vh;
  border: none;
}
