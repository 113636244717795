@use '/src/styles/variables' as *;

.img {
  width: 100%;
  height: 100%;
}

.wrapper {
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1200px) {
    position: static;
  }

  &__h2 {
    font-size: 80px;
    color: $yellow;
    font-family: 'Proxima Nova', sans-serif !important;
    font-weight: 700;
    @media screen and (max-width: 1200px) {
      margin-top: 20px;
      font-size: 50px;
    }
  }

  &__p {
    font-size: 24px;
    color: #282828;
    font-weight: 500;
    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }
  }
}

.socialBox {
  width: 40%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 1200px) {
    width: 68%;
  }
}
