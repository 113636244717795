@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

// @import url('https://db.onlinewebfonts.com/c/aa46d234cf405126cdd1c36600e4b978?family=Proxima+Nova');

// @import url('https://use.typekit.net/uho3fcj.css');

@font-face {
  font-family: 'Proxima Nova';
  src: url(styles/proxima.ttf) format('truetype');
}

* {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  font-weight: 400;
  color: #282828;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

a {
  text-decoration: none;
  color: inherit !important;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue, Proxima Nova Black', 'Proxima Nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
