.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5rem 0;
  @media (max-width: 999px) {
    height: 100%;
    padding: 2rem 2rem;
  }
  &__title {
    font-family: Proxima Nova;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 5rem;
    @media (max-width: 999px) {
      font-size: 30px;
    }
  }
}

.wrapper {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  @media (max-width: 999px) {
    flex-direction: column;
  }
}
