@use '/src/styles/variables' as *;
@use '/src/styles/animations' as *;

.infoWrapper {
  animation: fadeInAnimation 0.7s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  width: fit-content;
  @media (min-width: 1000px) and (max-width: 1440px) {
    width: 65%;
  }
  @media (max-width: 999px) {
    width: 100%;
  }
  & h1 {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
  }
  &__title {
    font-family: Proxima Nova, sans-serif !important;
    font-size: 72px;
    font-weight: 900;
    @media (max-width: 999px) {
      font-size: 60px;
    }
  }
  &__description {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    line-height: 24px;
  }
}

.kcalWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px 0 20px 0;
  &__kcalItem {
    background-color: $white;
    padding: 5px 15px;
    border-radius: 45px;
    margin: 0 5px 10px 0;
    font-size: 14px;
    font-weight: 700;
    color: $grey;
  }
  &__span {
    font-weight: 400;
  }
  @media (max-width: 999px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1000px) and (max-width: 1170px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.iconsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 0 3rem 0;
  list-style: none;
  &__item {
    text-align: center;
    margin-right: 2rem;
    width: 35%;
    height: 35%;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  @media (max-width: 999px) {
    align-items: flex-start;
    flex-direction: column;
  }

  & a {
    padding: 0px 30px 0 0;
    color: $black;
    font-size: 14px;
    @media (max-width: 999px) {
      padding: 20px 0 0 0;
    }
  }
}
