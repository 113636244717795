@use '/src/styles/variables' as *;

.container {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px 60px 50px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  @media (max-width: 1200px) {
    padding: 0;
    border-radius: 45px 0 0 45px;
  }
}

.containerScroll {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px 60px 50px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 1200px) {
    padding: 30px 0 70px 0;
  }
}

.logo {
  position: absolute;
  top: 2.1rem;
  left: 44%;
  z-index: 999;
  @media (max-width: 1200px) {
    top: 1.5rem;
  }

  @media (min-width: 300px) and (max-width: 374px) {
    left: 2.6rem;
  }
  @media (min-width: 375px) and (max-width: 400px) {
    left: 4.8rem;
  }
  @media (min-width: 401px) and (max-width: 470px) {
    left: 6.8rem;
  }
  @media (min-width: 471px) and (max-width: 520px) {
    left: 8.8rem;
  }
  @media (min-width: 521px) and (max-width: 600px) {
    left: 11.2rem;
  }
  @media (min-width: 601px) and (max-width: 700px) {
    left: 13rem;
  }
  @media (min-width: 701px) and (max-width: 770px) {
    left: 18rem;
  }
}

.mobileNavToogle {
  cursor: pointer;
  display: none;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  z-index: 9999;
  overflow-x: visible;
  & img {
    width: 30px;
    height: 30px;
  }
  &__second {
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.5s ease-in-out;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 3px;
      background-color: black;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__first {
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 3px;
      background-color: black;
    }

    &::before {
      top: 40%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    &::after {
      top: 60%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  @media (max-width: 1200px) {
    display: block;
    position: absolute;
    top: 30px;
    right: 20px;
  }
}
.listItemWrapper {
  list-style: none;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    postion: absolute;
    top: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    width: 100%;
    padding: 0 30px;
  }
}
.listItem {
  padding: 0 20px 0 20px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 14px 30px;
    border-bottom: 1px solid $light-grey;
    width: 100%;
  }
}
.listItemButton {
  padding: 0 20px 0 20px;
  text-decoration: none;
  @media (max-width: 999px) {
    padding: 10px 0;
  }
}
.buttonLogo {
  background-color: transparent;
  border: none;
  padding: 0;
}
.listItemRight {
  padding: 0 25px 0 0;
  @media (max-width: 1200px) {
    padding: 0;
  }
}

.iconsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}
