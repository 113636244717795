@use '/src/styles/variables' as *;

.button {
  cursor: pointer;
  border: none;
  border-radius: 25px;
  padding: 10px 32px;
  font-size: 14px;
  font-weight: 400;
  @media (max-width: 999px) {
    width: 100%;
  }
}
