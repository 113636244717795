@use '/src/styles/animations' as *;
.container {
  animation: fadeInAnimation 0.7s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  @media (min-width: 1000px) and (max-width: 1100px) {
    position: absolute;
    top: 34%;
  }
  @media (min-width: 1100px) and (max-width: 1310px) {
    position: absolute;
    top: 32%;
  }

  @media (min-width: 1310px) and (max-width: 1440px) {
    position: absolute;
    top: 32%;
  }
  @media (max-width: 999px) {
    display: none;
  }
}

.background {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  @media (min-width: 1000px) and (max-width: 1100px) {
    max-width: 90%;
  }
  @media (min-width: 1100px) and (max-width: 1309px) {
    max-width: 90%;
  }
  @media (min-width: 1310px) and (max-width: 1440px) {
    max-width: 90%;
  }
}
.dietImage {
  position: absolute;
  max-width: 90%;
  max-height: 100%;
  @media (min-width: 1000px) and (max-width: 1100px) {
    max-width: 90%;
  }
  @media (min-width: 1100px) and (max-width: 1309px) {
    max-width: 90%;
  }
  @media (min-width: 1310px) and (max-width: 1440px) {
    max-width: 90%;
  }
}
